module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-WE86KT55P8"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Atelier de croitorie si retusuri Carol 59","short_name":"Atelier de croitorie Carol 59","description":"Atelier de croitorie si retusuri Carol 59. Rochii de seara pe comanda, rochii de domnisoara de onoare, de nunta, fuste pantaloni. Retusuri de orice fel.","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","icon":"src/images/favicon3.jpeg","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"98194fcf4af839e520d5a2fb0d23ce6e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
